<template>
  <v-container class="ma-0 pa-0" id="contactus" fluid>
    <v-row no-gutters>
        <v-col cols=2 class="hidden-sm-and-down">
        </v-col>
        <v-col cols=12 sm=7 md=6 align-self="start" class="px-4 px-sm-6 px-lg-13">
          <v-row no-gutters>
            <v-col cols=auto>
              <p class="font-weight-medium text-h5 px-0 text-md-h4 text-lg-h3 primary--text">Contact us</p>
            </v-col>
          </v-row>
          <p class="font-weight-regular text-subtitle-1 text-md-h6 mt-2 mt-md-6">
            If you have any questions or want to <strong>get a free estimate</strong> for your mold situation contact us via email or phone call. We will be very happy to help you!
          </p>
          <v-row no-gutters class="mt-2 mt-md-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0">
                  <v-list-item-icon class="mx-0 align-self-center justify-center flex-grow-0 flex-shrink-0 secondary--text"><icon-phone :width="phoneIcon.width" :height="phoneIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-2 ml-md-5 text-subtitle-1 text-md-h6 font-weight-medium flex-grow-1 flex-shrink-0 align-self-center" style="max-width: 100%">
                    <a href="tel:470-601-1911" class="text-decoration-none">470-601-1911</a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2 mt-md-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0">
                  <v-list-item-icon class="mx-0 align-self-center justify-center flex-grow-0 flex-shrink-0 secondary--text"><icon-mail :width="mailIcon.width" :height="mailIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-2 ml-md-5 text-subtitle-1 text-md-h6 font-weight-medium flex-grow-1 flex-shrink-0 align-self-center" style="max-width: 100%">
                    <a href="mailto:contact@moldexpertsofatlanta.com" target="_blank" class="text-decoration-none">contact@moldexpertsofatlanta.com</a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2 mt-md-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0">
                  <v-list-item-icon class="mx-0 align-self-center justify-center flex-grow-0 flex-shrink-0 secondary--text"><icon-marker :width="markerIcon.width" :height="markerIcon.height"/></v-list-item-icon>
                  <v-list-item-title class="ml-2 ml-md-5 text-subtitle-1 text-md-h6 font-weight-medium flex-grow-1 flex-shrink-0 align-self-center" style="max-width: 100%">
                    2687 Brookwest Ln. SW, Marietta, GA, 30064
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2 mt-md-8">
            <v-col>
              <v-list flat class="pa-0">
                <v-list-item class="pa-0 my-0">
                  <v-list-item-icon class="mx-0 align-self-center justify-center flex-grow-0 flex-shrink-0 secondary--text">
                    <icon-clock :width="clockIcon.width" :height="clockIcon.height"
                        backgroundColor="white"/>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2 ml-md-5 text-subtitle-1 text-md-h6 font-weight-medium flex-grow-1 flex-shrink-0 align-self-center" style="max-width: 100%">
                    Monday - Friday:<br/>8:00 am - 5:00 pm<br/>
                    24/7 Emergency Service
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row> 
        </v-col>
        <v-col cols=5 md=4 class="d-none d-sm-block">
          <v-row no-gutters>
            <v-col class="white"><v-card elevation=0 class="white" position="right" height="40"></v-card></v-col>
          </v-row>
          <v-row no-gutters>
            <v-container class="ma-0 pa-0 pr-4 pr-sm-6 pr-lg-13">
              <v-row no-gutters>
                <v-col class="secondary px-10">
                  <v-img src="../assets/images/contactus_3x_md.png"
                  :srcset="require('../assets/images/contactus_3x_lg.png') + ' 1272w,' +
                           require('../assets/images/contactus_2x_lg.png') + ' 848w,' +
                           require('../assets/images/contactus_3x_md.png') + ' 630w,' +                           
                           require('../assets/images/contactus_1x_lg.png') + ' 424w,' +
                           require('../assets/images/contactus_2x_md.png') + ' 420w,' +
                           require('../assets/images/contactus_1x_md.png') + ' 210w,'" />

                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
    </v-row>
    <p class="py-5 py-md-8"></p>
  </v-container>  
</template>

<script>
  import IconMail from './icons/IconMail.vue'
  import IconClock from './icons/IconClock.vue'
  import IconMarker from './icons/IconMarker.vue'
  import IconPhone from './icons/IconPhone.vue'
  export default {
    name: 'ContactUs',    
    components: {
      IconMail,
      IconClock,
      IconMarker,
      IconPhone
    },
    data: () => ({
      reasons: [
        { title: "Quick response time" },
        { title: "Incredible customer service" },
        { title: "High level of professionalism" },
        { title: "Work completed on time" },
        { title: "Competitive prices" },
        { title: "We understand our customers’ needs" },
        { title: "Latest technology and equipment" },
        { title: "Your property will be treated with care" },
      ]
    }),
    computed: {
      clockIcon () {
        var regular = {
          width: 25,
          height: 25
        }
        var small = {
          width: 18,
          height: 18
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      mailIcon () {
        var regular = {
          width: 25,
          height: 17
        }
        var small = {
          width: 18,
          height: 12
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      phoneIcon () {
        var regular = {
          width: 20,
          height: 20
        }
        var small = {
          width: 13,
          height: 13
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
      markerIcon () {
        var regular = {
          width: 18,
          height: 25
        }
        var small = {
          width: 13,
          height: 18
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },
    },
  }
</script>

<style scoped>
  div >>> div.secondary > div.v-image {
    margin-top: -26px !important;
  }
</style>