<template>
  <v-container class="ma-0 pa-0">
  <v-app-bar app fixed flat extended color="white" class="pt-7">
    <v-row class="d-flex ma-0 justify-space-between align-center pl-4 pl-sm-6 pl-lg-13 pr-4 pr-sm-6 pr-lg-13 flex-nowrap">
      <v-col class="flex-shrink-1 flex-grow-0 px-0">
        <v-container v-if="showRegularLogo" class="ma-0 pa-0">
          <v-img class="px-0" src="../assets/logo/logo_1x_lg.png" contain width="120" height="65"
          :srcset="require('../assets/logo/logo_3x_lg.png') + ' 360w,' +
                  require('../assets/logo/logo_2x_lg.png') + ' 240w,' +
                  require('../assets/logo/logo_1x_lg.png') + ' 120w,'" />                
        </v-container>
        <v-container v-if="!showRegularLogo" class="ma-0 pa-0">
          <v-img class="px-0" src="../assets/logo/logo_1x_md.png" contain width="83" height="45"
          :srcset="require('../assets/logo/logo_3x_md.png') + ' 249w,' +
                  require('../assets/logo/logo_2x_md.png') + ' 166w,' +
                  require('../assets/logo/logo_1x_md.png') + ' 83w,'" />
        </v-container>
      </v-col> 
      <v-col v-if="showFullNav">
        <v-tabs :height="logoHeight" centered hide-slider>
        <!-- This has to be a component so that I can use v-if to render the image (or not)
             based on the truthiness of imgpath -->
        <NavBarItem v-for="item in menu_items" :key="item.title" :classval="item.classval" :title="item.title" :imgname="item.imgname" :imgwidth="item.imgwidth" :imgheight="item.imgheight" :link="item.link" />
        </v-tabs>
      </v-col>
    <v-col class="flex-shrink-1 flex-grow-0 font-weight-bold text-subtitle-1 primary--text text-no-wrap px-0 pl-md-3">
      <v-container fluid class="ma-0 pa-0">
        <v-row no-gutters class="d-flex flex-nowrap justify-end align-center">
          <v-col class="flex-shrink-1 flex-grow-0">
            <a href="tel:470-601-1911" class="text-decoration-none secondary--text">
            <icon-phone class="mr-2 flex-shrink-1 flex-grow-0" :width="phoneIcon.width" :height="phoneIcon.height"/>
            </a>
          </v-col>
          <v-col class="flex-shrink-1 flex-grow-0">
            <p class="ma-0 mb-md-2 pa-0 text-body-2 text-md-h6 font-weight-bold"><a href="tel:470-601-1911" class="text-decoration-none">470-601-1911</a></p>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col v-if="!showFullNav" class="px-0 flex-shrink-1 flex-grow-0">
      <v-card :width="logoWidth" tile flat>
        <v-card-title class="justify-end pa-0">
        <v-img src="../assets/burger.svg" @click.stop="drawer = !drawer" contain max-height=35 max-width=35 height=35 width=35 />
        </v-card-title>
      </v-card>
    </v-col>
    </v-row>
  </v-app-bar>
  <v-navigation-drawer
        v-model="drawer"
        relative
        left
        temporary
        app
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="secondary--text"
          >
            <v-list-item v-for="item in drawer_items" :key="item.title">
              <v-list-item-title @click="$vuetify.goTo(item.link)">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
  </v-navigation-drawer>
</v-container>
</template>

<script>
  import NavBarItem from './NavBarItem';
  import IconPhone from './icons/IconPhone'
  export default {
    components: {
      NavBarItem,
            IconPhone  
    },      
    name: 'NavBar',

    breakpoint: {
      mobileBreakpoint: 'sm' // This is equivalent to a value of 960
    },
    
    computed: {
      showFullNav () {
        if (this.$vuetify.breakpoint.width > this.showNavBarMinWidth) { return true }
        else { return false}
      },
      phoneIcon() {
        var regular = {
          width: 25,
          height: 25
        }
        var small = {
          width: 13,
          height: 13
        }
        if (this.$vuetify.breakpoint.mdAndUp) { 
          return regular
        }
        else {
          return small
        }
      },      
      logoWidth () {
        if (this.$vuetify.breakpoint.mdAndUp) { return "120" }
        else { return "83"}
      },
      showRegularLogo () {
        if (this.$vuetify.breakpoint.width > this.showNavBarMinWidth) { return true }
        else { return false}
      },      
      logoHeight () {
        if (this.$vuetify.breakpoint.mdAndUp) { return "65" }
        else { return "45"}
      },
      target () {
          return this.link
      }
    },
    data: () => ({
      menu_items: [
        { title: "About", link: "#about" },
        { title: "Services", link: "#services" },
        { title: "How it Works", link: "#howitworks" },
        { title: "Gallery", link: "#gallery" },
        { title: "Reviews", link: "#reviews" },
        { title: "Pricing", link: "#pricing" },
        { title: "Contact Us", link: "#contactus" },
      ],
      drawer_items: [
        { title: "Home", link: "#home" },
        { title: "About", link: "#about" },
        { title: "Services", link: "#services" },
        { title: "How it Works", link: "#howitworks" },
        { title: "Equipment", link: "#equipment" },
        { title: "Service Area", link: "#servicearea" },
        { title: "Why Us", link: "#whyus" },
        { title: "Gallery", link: "#gallery" },
        { title: "Reviews", link: "#reviews" },
        { title: "Order Process", link: "#orderprocess" },
        { title: "Pricing", link: "#pricing" },
        { title: "Contact Us", link: "#contactus" },
      ],
      showNavBarMinWidth: 1220,
      drawer: false,
      group: null
    }),

    watch: {
      group () {
        this.drawer = false
      }
    }
  }
</script>

<style type="text/css">
  .v-toolbar__content {
    padding: 0px 0px !important;
  }
</style>