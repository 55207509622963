<template>
  <v-container fluid id="about" class="ma-0 px-0 pb-0">
    <v-row>
      <v-col :class="showImage" />
      <v-col class="py-0 pb-md-3">
        <p class="font-weight-medium text-h5 px-4 px-sm-6 px-lg-13 text-md-h4 text-lg-h3 primary--text">About the Company</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col :class="showImageSecondary" cols=6 >
        <v-img contain eager class="mt-n10 px-11" src="../assets/images/moldpoint_1x_lg.jpg"
        :srcset="require('../assets/images/moldpoint_3x_lg.jpg') + ' 2490w,' + 
                require('../assets/images/moldpoint_2x_lg.jpg') + ' 1660w,' + 
                require('../assets/images/moldpoint_1x_lg.jpg') + ' 830w'" max-height="850" />
      </v-col>
      <v-col class="secondary" :cols="this.$vuetify.breakpoint.width <= breakpoint ? 12 : 6">
        <v-container class="px-0">
          <v-row class="mx-0">
            <v-col cols=1 :class="showImageSecondary">
              <v-divider vertical align="left" role="presentation" class="white" />
            </v-col>
            <v-col :cols="numInnerColumns" class="py-0 py-md-3 px-4 px-sm-6 mt-7 mt-md-0">
              <p class="font-weight-medium mb-2 mb-md-4 text-subtitle-1 text-md-h5 white--text">What we Offer</p>
              <p class="font-weight-regular my-0 my-md-4 text-subtitle-2 text-md-subtitle-1 white--text">We offer the most comprehensive mold inspections & mold testing.</p>
            </v-col>
            <v-spacer />
          </v-row>
        <v-row class="mx-0 mt-lg-14">
            <v-col cols=1 :class="showImageSecondary">
              <v-divider vertical role="presentation" class="white" />
            </v-col>
            <v-col :cols="numInnerColumns" class="py-0 py-md-3 px-4 px-sm-6 mt-6 mt-md-0">
              <p class="font-weight-medium mb-2 mb-md-4 text-subtitle-1 text-md-h5 white--text">Our Values</p>
              <p class="font-weight-regular my-0 my-md-4 text-subtitle-2 text-md-subtitle-1 white--text">From our initial inspection to our final walkthrough, your safety and peace of mind come first.</p>
            </v-col>
            <v-spacer />
          </v-row>
        <v-row class="mx-0 mt-lg-14">
            <v-col cols=1 :class="showImageSecondary">
              <v-divider vertical role="presentation" class="white" />
            </v-col>
            <v-col :cols="numInnerColumns" class="py-0 py-md-3 px-4 px-sm-6 mt-6 mt-md-0 mb-7 mb-md-0">
              <p class="font-weight-medium mb-2 mb-md-4 text-subtitle-1 text-md-h5 white--text">Certified Professionals</p>
              <p class="font-weight-regular my-0 my-md-4 text-subtitle-2 text-md-subtitle-1 white--text">Samples are pulled by our certified mold inspectors are sent to PRO-LAB in Weston Florida for analysis. Certified mold remediation follows the IICRC S520 protocols.</p>
            </v-col>
            <v-spacer />
          </v-row>        
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {    
    name: 'AboutUs',

    data: () => ({
      breakpoint: 768
    }),
    computed: {
      showImage () {
        if (this.$vuetify.breakpoint.width <= 768) { return "d-none" }
        else { return ""}
      },
      showImageSecondary () {
        if (this.$vuetify.breakpoint.width <= 768) { return "d-none" }
        else { return "secondary pl-sm-9 pl-lg-16 pr-4"}
      },
      numInnerColumns () {
        if (this.$vuetify.breakpoint.width > 650) {
          return 7
        }
        else {
          return 12
        }
      }
    },
  }
</script>

